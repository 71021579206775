import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import * as actions from '../../redux/actions';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { ViewTitle } from 'components/ViewTitle';
import Loading from 'components/Loading';
import ExpensesApplyForm from 'components/Forms/ExpensesApplyForm';
import { Card } from '@mui/material';
import { BackButton } from 'components/Button';

export default function EditExpenses({ title, subtitle }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const expenses = useSelector((state) => state.expenses);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm();

  const [date, setDate] = useState(expenses?.date);
  const [paidType, setPaidType] = useState(expenses?.paidType);

  useEffect(() => {
    dispatch(actions.expensesGet(id));
    return () => {};
  }, []);

  useEffect(() => {
    setValue('name', expenses?.name);
    setValue('price', expenses?.price);
    setValue('code', expenses?.code);
    setDate(expenses?.date);
    setPaidType(expenses?.paidType);
    return () => {};
  }, [expenses]);

  const renderTitle = () => (
    <ViewTitle title={title} subtitle={subtitle} expenses={expenses} />
  );

  const onSubmit = async (data) => {
    const confirm = window.confirm('บันทึกข้อมูล');
    if (confirm) {
      const dataSubmit = {
        ...data,
        paidType,
        date,
      };
      await dispatch(actions.expensesPut(id, dataSubmit));
      await dispatch(actions.expensesAll({}));
      history.goBack();
    }
  };

  const renderForm = () => (
    <Card>
      <div className="p-2">
        <form onSubmit={handleSubmit(onSubmit)}>
          <ExpensesApplyForm
            expenses={expenses}
            control={control}
            Controller={Controller}
            date={date}
            setDate={setDate}
            paidType={paidType}
            setPaidType={setPaidType}
            edit={true}
          />
        </form>
      </div>
    </Card>
  );

  if (!expenses?.isLoading && expenses?.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        {renderForm()}
        *หากข้อมูลไม่แสดงรีเฟรช 1 ครั้ง
      </div>
    );
  }
  return <Loading isLoading />;
}
