import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import _ from 'lodash';
import React from 'react';
import ProgressBar from 'react-percent-bar';

export default function BudgetTable({
  budget,
  handleEditBudget,
  handleDeleteBudget,
}) {
  const renderCost = (data) => {
    const fil = _?.filter(data?.expenses, (each) => each?.status === 'SUCCESS');
    const cost1 = _?.sumBy(fil, (e) => e?.price);
    return cost1;
  };

  const renderCostApprove = (data) => {
    const fil = _?.filter(data?.expenses, (each) => each?.status === 'APPROVE');
    const cost1 = _?.sumBy(fil, (e) => e?.price);
    return cost1;
  };

  const renderPercen = (data) => {
    const fil = _?.filter(data?.expenses, (each) => each?.status === 'SUCCESS');
    const cost1 = _?.sumBy(fil, (e) => e?.price);

    const per = (cost1 / (data?.cost === 0 ? 1 : data?.cost)) * 100;

    if (per >= 100) return 100;
    else return per;
  };

  const renderTotal = (data) => {
    const a = renderCost(data);
    const b = renderCostApprove(data);
    return data?.cost - a - b;
  };

  return (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow className={'bg-gray-600'}>
                <TableCell>
                  <div className="text-white">No</div>
                </TableCell>
                <TableCell>
                  <div className="text-white">Order</div>
                </TableCell>
                <TableCell>
                  <div className="text-white">Budget</div>
                </TableCell>
                <TableCell>
                  <div className="text-white">จ่ายไปแล้ว</div>
                </TableCell>
                <TableCell>
                  <div className="text-white">percent</div>
                </TableCell>
                <TableCell>
                  <div className="text-white">คาดว่าต้องจ่าย</div>
                </TableCell>
                <TableCell>
                  <div className="text-white">เหลือจ่าย</div>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_?.map(budget, (e) => (
                <TableRow key={e.id}>
                  <TableCell>
                    {e?.prefix}
                    {e?.budget_number}
                  </TableCell>
                  <TableCell>{e?.name}</TableCell>
                  <TableCell>
                    {e?.cost?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                  </TableCell>
                  <TableCell>
                    {renderCost(e)
                      ?.toFixed(2)
                      ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                  </TableCell>
                  <TableCell>
                    <div className="flex">
                      <ProgressBar
                        percent={renderPercen(e)}
                        fillColor={
                          renderPercen(e) < 50
                            ? 'green'
                            : renderPercen(e) < 79
                            ? '#FFB200'
                            : 'red'
                        }
                        width="70px"
                        height="20px"
                      />
                      {renderPercen(e)
                        ?.toFixed(2)
                        ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                      %
                    </div>
                  </TableCell>
                  <TableCell>
                    {renderCostApprove(e)
                      ?.toFixed(2)
                      ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                  </TableCell>
                  <TableCell>
                    {renderTotal(e)
                      ?.toFixed(2)
                      ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => handleEditBudget(e)}
                    >
                      <ModeEditIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => handleDeleteBudget(e?.id)}
                    >
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
