import {
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import React from 'react';

export default function ProjectTable({
  projects,
  handleOnclickDetail,
  page,
  setPage,
  size,
  setSize,
}) {
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    // console.log("page ", page);
  };
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };
  return (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow className={'bg-gray-600'}>
                <TableCell>
                  {' '}
                  <h1 className=" text-white ">Project Number</h1>
                </TableCell>
                <TableCell>
                  <h1 className=" text-white  ">Project</h1>
                </TableCell>
                <TableCell>
                  <h1 className="  text-white ">value</h1>
                </TableCell>
                <TableCell>
                  <h1 className=" text-white  ">status</h1>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_?.map(projects?.rows, (e) => (
                <TableRow key={e.id} style={{ height: 60 }}>
                  <TableCell>{e?.project_number}</TableCell>
                  <TableCell>{e?.name}</TableCell>
                  <TableCell>
                    {e?.cost?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                  </TableCell>
                  <TableCell>
                    <div>
                      <div>
                        {e?.deliver_status?.status ? (
                          <Chip
                            label="delivred"
                            color="success"
                            size="small"
                          ></Chip>
                        ) : (
                          <Chip
                            label="pending"
                            color="error"
                            size="small"
                          ></Chip>
                        )}
                      </div>
                      {!_.isEmpty(e?.billing) && (
                        <div className="py-1">
                          <Chip
                            label="Billing"
                            color="success"
                            size="small"
                          ></Chip>
                        </div>
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => handleOnclickDetail(e?.id)}
                    >
                      detail
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 30, 40, 50, 100]}
          component="div"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          count={projects?.total || 0}
          rowsPerPage={size}
          page={page - 1}
        />
      </Paper>
    </div>
  );
}
