// common type

export const ME_GET = 'ME_GET';
export const ME_RESET = 'ME_RESET';

export const USER_ALL = 'USER_ALL';
export const USER_GET = 'USER_GET';
export const USER_POST = 'USER_POST';
export const USER_PUT = 'USER_PUT';
export const USER_DEL = 'USER_DEL';
export const USER_CREATE = 'USER_CREATE';
export const USER_LOADING = 'USER_LOADING';
export const USER_ERROR = 'USER_ERROR';

// feature type
export const ROLETYPES_ALL = 'ROLETYPES_ALL';
export const ROLETYPES_GET = 'ROLETYPES_GET';
export const ROLETYPES_POST = 'ROLETYPES_POST';
export const ROLETYPES_PUT = 'ROLETYPES_PUT';
export const ROLETYPES_DEL = 'ROLETYPES_DEL';
export const ROLETYPES_LOADING = 'ROLETYPES_LOADING';
export const ROLETYPES_ERROR = 'ROLETYPES_ERROR';

export const EMPLOYEE_ALL = 'EMPLOYEE_ALL';
export const EMPLOYEE_GET = 'EMPLOYEE_GET';
export const EMPLOYEE_POST = 'EMPLOYEE_POST';
export const EMPLOYEE_PUT = 'EMPLOYEE_PUT';
export const EMPLOYEE_DEL = 'EMPLOYEE_DEL';
export const EMPLOYEE_RESET = 'EMPLOYEE_RESET';
export const EMPLOYEE_LOADING = 'EMPLOYEE_LOADING';
export const EMPLOYEE_ERROR = 'EMPLOYEE_ERROR';

export const DROPLET_PROJECT_ALL = 'DROPLET_PROJECT_ALL';
export const DROPLET_PROJECT_GET = 'DROPLET_PROJECT_GET';
export const DROPLET_PROJECT_POST = 'DROPLET_PROJECT_POST';
export const DROPLET_PROJECT_PUT = 'DROPLET_PROJECT_PUT';
export const DROPLET_PROJECT_DEL = 'DROPLET_PROJECT_DEL';
export const DROPLET_PROJECT_ERROR = 'DROPLET_PROJECT_ERROR';
export const DROPLET_PROJECT_LOADING = 'DROPLET_PROJECT_LOADING';

export const DROPLET_ALL = 'DROPLET_ALL';
export const DROPLET_GET = 'DROPLET_GET';
export const DROPLET_POST = 'DROPLET_POST';
export const DROPLET_PUT = 'DROPLET_PUT';
export const DROPLET_DEL = 'DROPLET_DEL';
export const DROPLET_ERROR = 'DROPLET_ERROR';
export const DROPLET_LOADING = 'DROPLET_LOADING';

export const PROJECT_ALL = 'PROJECT_ALL';
export const PROJECT_GET = 'PROJECT_GET';
export const PROJECT_POST = 'PROJECT_POST';
export const PROJECT_PUT = 'PROJECT_PUT';
export const PROJECT_DEL = 'PROJECT_DEL';
export const PROJECT_ERROR = 'PROJECT_ERROR';
export const PROJECT_LOADING = 'PROJECT_LOADING';
export const PROJECT_RESET = 'PROJECT_RESET';

export const SYSTEM_ALL = 'SYSTEM_ALL';
export const SYSTEM_GET = 'SYSTEM_GET';
export const SYSTEM_POST = 'SYSTEM_POST';
export const SYSTEM_PUT = 'SYSTEM_PUT';
export const SYSTEM_DEL = 'SYSTEM_DEL';
export const SYSTEM_ERROR = 'SYSTEM_ERROR';
export const SYSTEM_LOADING = 'SYSTEM_LOADING';

export const BUDGET_ALL = 'BUDGET_ALL';
export const BUDGET_GET = 'BUDGET_GET';
export const BUDGET_POST = 'BUDGET_POST';
export const BUDGET_PUT = 'BUDGET_PUT';
export const BUDGET_DEL = 'BUDGET_DEL';
export const BUDGET_ERROR = 'BUDGET_ERROR';
export const BUDGET_LOADING = 'BUDGET_LOADING';
export const BUDGET_RESET = 'BUDGET_RESET';

export const EXPENSES_ALL = 'EXPENSES_ALL';
export const EXPENSES_GET = 'EXPENSES_GET';
export const EXPENSES_POST = 'EXPENSES_POST';
export const EXPENSES_PUT = 'EXPENSES_PUT';
export const EXPENSES_DEL = 'EXPENSES_DEL';
export const EXPENSES_ERROR = 'EXPENSES_ERROR';
export const EXPENSES_LOADING = 'EXPENSES_LOADING';
export const EXPENSES_RESET = 'EXPENSES_RESET';

export const INFORMATION_ALL = 'INFORMATION_ALL';
export const INFORMATION_PUT = 'INFORMATION_PUT';
export const INFORMATION_LOADING = 'INFORMATION_LOADING';
export const INFORMATION_ERROR = 'INFORMATION_ERROR';

export const NOTIFY_REQ_TOKEN = 'NOTIFY_REQ_TOKEN';
export const NOTIFY_DIRECT = 'NOTIFY_DIRECT';
export const NOTIFY_DEPARTMENT = 'NOTIFY_DEPARTMENT';
export const NOTIFY_LOADING = 'NOTIFY_LOADING';
export const NOTIFY_ERROR = 'NOTIFY_ERROR';

export const INFO_GET = 'INFO_GET';
export const INFO_CREATE = 'INFO_CREATE';
export const INFO_PUT = 'INFO_PUT';
export const INFO_RESET = 'INFO_RESET';
export const INFO_LOADING = 'INFO_LOADING';
export const INFO_ERROR = 'INFO_ERROR';

export const CUSTOMER_ALL = 'CUSTOMER_ALL';
export const CUSTOMER_GET = 'CUSTOMER_GET';
export const CUSTOMER_POST = 'CUSTOMER_POST';
export const CUSTOMER_PUT = 'CUSTOMER_PUT';
export const CUSTOMER_DEL = 'CUSTOMER_DEL';
export const CUSTOMER_RESET = 'CUSTOMER_RESET';
export const CUSTOMER_LOADING = 'CUSTOMER_LOADING';
export const CUSTOMER_ERROR = 'CUSTOMER_ERROR';

export const CUSTOMER_TYPE_ALL = 'CUSTOMER_TYPE_ALL';
export const CUSTOMER_TYPE_GET = 'CUSTOMER_TYPE_GET';
export const CUSTOMER_TYPE_POST = 'CUSTOMER_TYPE_POST';
export const CUSTOMER_TYPE_PUT = 'CUSTOMER_TYPE_PUT';
export const CUSTOMER_TYPE_DEL = 'CUSTOMER_TYPE_DEL';
export const CUSTOMER_TYPE_LOADING = 'CUSTOMER_TYPE_LOADING';
export const CUSTOMER_TYPE_ERROR = 'CUSTOMER_TYPE_ERROR';

export const DEPARTMENT_ALL = 'DEPARTMENT_ALL';
export const DEPARTMENT_GET = 'DEPARTMENT_GET';
export const DEPARTMENT_POST = 'DEPARTMENT_POST';
export const DEPARTMENT_PUT = 'DEPARTMENT_PUT';
export const DEPARTMENT_DEL = 'DEPARTMENT_DEL';
export const DEPARTMENT_LOADING = 'DEPARTMENT_LOADING';
export const DEPARTMENT_ERROR = 'DEPARTMENT_ERROR';

export const TIMESTAMP_ALL = 'TIMESTAMP_ALL';
export const TIMESTAMP_GET = 'TIMESTAMP_GET';
export const TIMESTAMP_POST = 'TIMESTAMP_POST';
export const TIMESTAMP_PUT = 'TIMESTAMP_PUT';
export const TIMESTAMP_DEL = 'TIMESTAMP_DEL';
export const TIMESTAMP_ERROR = 'TIMESTAMP_ERROR';
export const TIMESTAMP_LOADING = 'TIMESTAMP_LOADING';
export const TIMESTAMP_RESET = 'TIMESTAMP_RESET';

export const HOLIDAY_ALL = 'HOLIDAY_ALL';
export const HOLIDAY_GET = 'HOLIDAY_GET';
export const HOLIDAY_POST = 'HOLIDAY_POST';
export const HOLIDAY_PUT = 'HOLIDAY_PUT';
export const HOLIDAY_DEL = 'HOLIDAY_DEL';
export const HOLIDAY_LOADING = 'HOLIDAY_LOADING';
export const HOLIDAY_ERROR = 'HOLIDAY_ERROR';

export const BILLING_ALL = 'BILLING_ALL';
export const BILLING_GET = 'BILLING_GET';
export const BILLING_POST = 'BILLING_POST';
export const BILLING_PUT = 'BILLING_PUT';
export const BILLING_DEL = 'BILLING_DEL';
export const BILLING_LOADING = 'BILLING_LOADING';
export const BILLING_ERROR = 'BILLING_ERROR';
export const BILLING_RESET = 'BILLING_RESET';
