import { combineReducers } from 'redux';

// Common
import UserReducers from './common/UserReducers';
import MeReducers from './common/MeReducers';
// feature
import RoleTypesReducer from './features/RoleTypesReducer';
import EmployeeReducer from './features/EmployeeReducer';
import ProjectReducer from './features/ProjectReducer';
import BudgetReducer from './features/BudgetReducer';
import ExpensesReducer from './features/ExpensesReducer';
import InfoReducers from './features/InfoReducers';
import NotifyReducer from './features/NotifyReducer';
import CustomerReducer from './features/CustomerReducer';
import CustomerTypeReducer from './features/CustomerTypeReducer';
import DepartmentReducer from './features/DepartmentReducer';
import TimestampReducer from './features/TimestampReducer';
import HolidayReducer from './features/HolidayReducer';
import BillingReducer from './features/BillingReducer';

const rootRuducer = combineReducers({
  me: MeReducers,
  user: UserReducers,
  roletype: RoleTypesReducer,
  employee: EmployeeReducer,
  project: ProjectReducer,
  budget: BudgetReducer,
  expenses: ExpensesReducer,
  notify: NotifyReducer,
  info: InfoReducers,
  customer: CustomerReducer,
  customerType: CustomerTypeReducer,
  department: DepartmentReducer,
  timestamp: TimestampReducer,
  holiday: HolidayReducer,
  billing: BillingReducer,
});
export default rootRuducer;
