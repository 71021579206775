import { Button, Card } from '@mui/material';
import { ViewTitle } from 'components/ViewTitle';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/actions';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ProjectTable } from 'components/Table';

export default function ProjectList({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.project);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  useEffect(() => {
    dispatch(actions.projectAll({ page, size }));

    return () => {};
  }, [page, size]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleOnclickDetail = (id) => {
    history.push(`/project/project/detail/${id}`);
  };

  const renderTable = () => (
    <Card>
      <div>
        <ProjectTable
          projects={projects}
          handleOnclickDetail={handleOnclickDetail}
          page={page}
          setPage={setPage}
          size={size}
          setSize={setSize}
        />
      </div>
    </Card>
  );

  const renderAddButton = () => (
    <Button
      variant="contained"
      onClick={() => history.push('/project/project/new')}
    >
      new project
    </Button>
  );

  return (
    <div>
      {renderTitle()}
      <div className="flex justify-end py-2">{renderAddButton()}</div>
      <div>{renderTable()}</div>
    </div>
  );
}
