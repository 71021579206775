import TimeStampCard from '../../components/Card/TimeStampCard';
import { ViewTitle } from 'components/ViewTitle';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as actions from '../../redux/actions';
import Loading from 'components/Loading';

export default function CheckIn({ title, subtitle }) {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const project = useSelector((state) => state.project);
  const history = useHistory();

  const [imgSrc, setImgSrc] = useState(null);
  const [noteCheckin, setNoteCheckin] = useState();
  const [projectSelect, setProjectSelect] = useState();

  useEffect(() => {
    dispatch(actions.meGet({}));
    dispatch(actions.projectAll({ status_deliver: true }));

    return () => {};
  }, []);
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async () => {
    if (!projectSelect) {
      alert('Please select a project');
    } else {
      const confirm = window.confirm('save');
      if (confirm) {
        const data = {
          employee: me?.userData?._id,
          employee_firstname: me?.userData?.firstname,
          employee_lastname: me?.userData?.lastname,
          status_checkIn: true,
          project_in: projectSelect,
          image: imgSrc,
        };
        await dispatch(actions.timestampCreate(data));
        // await dispatch(actions.timestampAll({}));
        history.goBack();
      }
    }
  };

  const renderCard = () => (
    <TimeStampCard
      me={me}
      date={new Date()}
      imgSrc={imgSrc}
      setImgSrc={setImgSrc}
      noteCheckin={noteCheckin}
      setNoteCheckin={setNoteCheckin}
      project={project}
      projectSelect={projectSelect}
      setProjectSelect={setProjectSelect}
      onSubmit={onSubmit}
    />
  );
  if (!project?.isLoading && project?.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderCard()}
      </div>
    );
  }
  return <Loading isLoading />;
}
