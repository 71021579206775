import { ViewTitle } from 'components/ViewTitle';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/actions';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Box,
  Modal,
  Tabs,
  Tab,
  Chip,
  Checkbox,
  IconButton,
} from '@mui/material';
import { BudgetForm } from 'components/Forms';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import BudgetTable from 'components/Table/BudgetTable';
import { BackButton } from 'components/Button';
import {
  useNavigate,
  useHistory,
} from 'react-router-dom/cjs/react-router-dom.min';
import { ExpensesTableApplove } from 'components/Table';
import { ExpensesTableProject } from 'components/Table';
import Loading from 'components/Loading';
import { DeliverForm } from 'components/Forms';
import dayjs from 'dayjs';
import { BillingForm } from 'components/Forms';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CheckInlist from 'components/Table/CheckInlist';
// import CheckInlist from 'components/Table/CheckInlist';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function ProjectDetail({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const project = useSelector((state) => state.project);
  const budget = useSelector((state) => state.budget);
  const timestamp = useSelector((state) => state.timestamp);

  const labor = _.sumBy(timestamp?.rows, (e) => e?.totalPrice);

  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [value1, setValue1] = React.useState(0);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [poStatus, setPostatus] = useState(project?.po_status);
  const [openDeliver, setOpenDeliver] = React.useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const [openBilling, setOpenBilling] = React.useState(false);
  const [dateDeliver, setDateDeliver] = useState(null);
  const [datePayment, setDatePayment] = useState(null);
  const [dateBilling, setDateBilling] = useState(null);
  const [dueDateBilling, setDueDateBilling] = useState(null);

  const handleChange = (event, newValue) => {
    setValue1(newValue);
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const handleOpenDeliver = () => setOpenDeliver(true);
  const handleCloseDeliver = () => setOpenDeliver(false);

  const handleOpenPayment = () => setOpenPayment(true);
  const handleClosePayment = () => setOpenPayment(false);

  const handleOpenBilling = () => setOpenBilling(true);
  const handleCloseBilling = () => setOpenBilling(false);

  // const renderBackButton = () => <BackButton />;

  useEffect(() => {
    dispatch(actions.projectGet(id));
    dispatch(actions.budgetAll({ project_id: id }));
    dispatch(actions.timestampAll({ project_id: id }));
    return () => {};
  }, [open, openEdit]);

  useEffect(() => {
    setPostatus(project?.po_status);
    return () => {};
  }, [project, budget]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm();

  const onSubmit = async (data) => {
    const confirm = window.confirm('บันทึก');
    if (confirm) {
      const dataSubmit = {
        ...data,
        project_id: id,
        prefix: 'SC-',
      };
      await dispatch(actions?.budgetCreate(dataSubmit));
      dispatch(actions?.projectGet(id));
      reset();
      handleClose();
      // history.goBack();
    }
  };

  const onSubmitEdit = async (data) => {
    const confirm = window.confirm('บันทึก');
    if (confirm) {
      const dataSubmit = {
        ...data,
        project_id: id,
        prefix: 'SC-',
      };
      await dispatch(actions?.budgetPut(data.id, dataSubmit));
      await dispatch(actions?.projectGet(id));
      reset();
      handleCloseEdit();
    }
  };

  const handlePo = async (e) => {
    const confirm = window.confirm('receive po');
    if (confirm) {
      await dispatch(actions?.projectPut(id, { po_status: e }));
      await dispatch(actions?.projectGet(id));
    }
  };

  const handleDeleteBudget = async (eid) => {
    const confirm = window.confirm('delete');
    if (confirm) {
      await dispatch(actions?.budgetDelete(eid));
      await dispatch(actions.projectGet(id));
      await dispatch(actions.budgetAll({ project_id: id }));
    }
  };

  const handleDelete = async (id) => {
    const confirm = window.confirm('ลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.expensesDelete(id));
        dispatch(actions.expensesAll({}));
      } catch (error) {}
    }
  };

  const handleEdit = (id) => {
    history.push(`/finance/expenses/edit/${id}`);
  };

  const handleApprove = async (id) => {
    await dispatch(actions.expensesPut(id, { status: 'APPROVE' }));
    dispatch(actions.expensesAll({}));
  };
  const handleSuccess = async (id) => {
    await dispatch(actions.expensesPut(id, { status: 'SUCCESS' }));
    dispatch(actions.expensesAll({}));
  };

  const handleEditBudget = (data) => {
    handleOpenEdit();
    setValue('name', data?.name);
    setValue('cost', data?.cost);
    setValue('id', data?.id);
  };

  const handleDeliver = async (data) => {
    if (!dateDeliver) {
      return alert('select date of delivery');
    } else {
      const confirm = window.confirm('confirm');
      if (confirm) {
        await dispatch(
          actions.projectPut(id, {
            deliver_status: { status: true, date: dateDeliver },
            operation_status: 'DELIVERY',
          }),
        );
        await dispatch(actions.projectGet(id));
        handleCloseDeliver();
      }
    }
  };

  const handlePayment = async (data) => {
    if (!datePayment) {
      return alert('select date of payment');
    } else {
      const confirm = window.confirm('confirm');
      if (confirm) {
        await dispatch(
          actions.projectPut(id, {
            payment_status: { status: true, date: datePayment },
            operation_status: 'COMPLETE',
          }),
        );
        await dispatch(actions.projectGet(id));
        handleClosePayment();
      }
    }
  };

  const handleBilling = async (data) => {
    if (!dueDateBilling) {
      return alert('select date of due');
    } else {
      const confirm = window.confirm('confirm');
      if (confirm) {
        await dispatch(
          actions.projectPut(id, {
            acc_status: {
              status: true,
              date: dateBilling,
              due: dueDateBilling,
            },
            operation_status: 'BILLING',
          }),
        );
        await dispatch(actions.projectGet(id));
        handleCloseBilling();
      }
    }
  };

  const renderModal = () => (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <BudgetForm Controller={Controller} control={control} />{' '}
            <div className="flex justify-center">
              <Button variant="contained" type="submit" size="small">
                save
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );

  const renderModalDeliver = () => (
    <div>
      <Modal
        open={openDeliver}
        onClose={handleCloseDeliver}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handleSubmit(handleDeliver)}>
            <DeliverForm
              dateDeliver={dateDeliver}
              setDateDeliver={setDateDeliver}
              title={'Delivery Order'}
            />{' '}
            <div className="flex justify-center">
              <Button variant="contained" type="submit" size="small">
                save
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );

  const renderModalBilling = () => (
    <div>
      <Modal
        open={openBilling}
        onClose={handleCloseBilling}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handleSubmit(handleBilling)}>
            <BillingForm
              dateBilling={dateBilling}
              setDateBilling={setDateBilling}
              dueDateBilling={dueDateBilling}
              setDueDateBilling={setDueDateBilling}
              title={'Billing'}
            />{' '}
            <div className="flex justify-center">
              <Button variant="contained" type="submit" size="small">
                save
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );

  const renderModalPayment = () => (
    <div>
      <Modal
        open={openPayment}
        onClose={handleClosePayment}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handleSubmit(handlePayment)}>
            <DeliverForm
              dateDeliver={datePayment}
              setDateDeliver={setDatePayment}
              title={'Payment Complete'}
            />{' '}
            <div className="flex justify-center">
              <Button variant="contained" type="submit" size="small">
                save
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );

  const renderModalEdit = () => (
    <div>
      <Modal
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handleSubmit(onSubmitEdit)}>
            edit
            <BudgetForm Controller={Controller} control={control} />{' '}
            <div className="flex justify-center">
              <Button variant="contained" type="submit" size="small">
                save
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );

  const renderTimestamp = () => <CheckInlist timestamp={timestamp} />;

  const renderBudget = () => (
    <Card>
      <div>
        <Box>
          <Tabs
            value={value1}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Budjet list" {...a11yProps(0)} />
            <Tab label="Expenses" {...a11yProps(1)} />
            <Tab label="Timestamp" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value1} index={0}>
          <div>
            <div className="flex justify-end">
              <Button variant="contained" onClick={handleOpen} size="small">
                Add budget
              </Button>
            </div>
            <BudgetTable
              budget={budget?.rows}
              handleEditBudget={handleEditBudget}
              handleDeleteBudget={handleDeleteBudget}
            />
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value1} index={1}>
          {renderTable()}
        </CustomTabPanel>
        <CustomTabPanel value={value1} index={2}>
          {renderTimestamp()}
        </CustomTabPanel>
      </div>
    </Card>
  );

  const renderDailCard = () => (
    <div>
      <Card>
        <div className="p-2">
          <div className="flex ">
            <h1 className="py-2 text-xl ">{project?.project_number}</h1>
            <div>
              <IconButton
                aria-label="delete"
                onClick={() => history.push(`/project/project/edit/${id}`)}
              >
                <ModeEditIcon fontSize="inherit" />
              </IconButton>
              {/* <IconButton
                aria-label="delete"
                size="small"
                onClick={() => handleDelete(e._id)}
              >
                <DeleteIcon fontSize="inherit" />
              </IconButton> */}
            </div>
          </div>
          <div className="grid lg:grid-cols-3  gap-2 ">
            <div>
              <p>Project: {project?.name}</p>
              <p>Customer: {project?.customer}</p>
              <p>Location: {project?.place}</p>
              <p>
                Prject Engineer: {project?.engineer?.firstname}{' '}
                {project?.engineer?.lastname}{' '}
              </p>
            </div>
            <div>
              <p>
                Quoted Price:{' '}
                {project?.cost
                  ?.toFixed(2)
                  ?.replace(/\d(?=(\d{3})+\.)/g, '$&,') || 0}
              </p>
              <p>
                Budget:{' '}
                {project?.total_budget
                  ?.toFixed(2)
                  ?.replace(/\d(?=(\d{3})+\.)/g, '$&,') || 0}
              </p>
              <p>
                Estimate Profit :{' '}
                {project?.estprofit
                  ?.toFixed(2)
                  ?.replace(/\d(?=(\d{3})+\.)/g, '$&,') || 0}
              </p>
              <p>
                Percent Estimate Profit : {project?.percentage?.toFixed(2) || 0}{' '}
                %
              </p>
            </div>
            <div>
              <h1>
                PO Status :{' '}
                {project?.po_status ? (
                  <Chip label="success" color="success" size="small" />
                ) : (
                  <Checkbox
                    size="small"
                    checked={project?.po_status}
                    onClick={(e) => handlePo(e.target.checked)}
                  />
                )}
              </h1>

              <h1 className="flex">
                Delivery Order :{' '}
                {project?.deliver_status?.status ? (
                  <div>
                    <Chip label="success" color="success" size="small" />{' '}
                    {dayjs(project?.deliver_status?.date).format('DD/MM/YYYY')}
                  </div>
                ) : (
                  <Chip label="waiting" color="error" size="small" />
                )}{' '}
              </h1>
              <h1>
                Billing :{' '}
                {!_.isEmpty(project?.billing) ? (
                  <Chip label="success" color="success" size="small" />
                ) : (
                  <Chip label="waiting" color="error" size="small" />
                )}{' '}
              </h1>
              {/* <h1>
                Payment Complete :{' '}
                {project?.payment_status?.status ? (
                  <Chip label="success" color="success" size="small" />
                ) : (
                  <Chip label="waiting" color="error" size="small" />
                )}{' '}
              </h1> */}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );

  const renderAnalyze = () => (
    <div className="py-2">
      <Card>
        <div className="p-1">
          Budget :{' '}
          {project?.total_budget
            ?.toFixed(2)
            ?.replace(/\d(?=(\d{3})+\.)/g, '$&,') || 0}{' '}
        </div>
        <div className="px-2 lg:grid grid-cols-2  ">
          <div>
            Estimate Labor :
            {labor?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, '$&,') || 0}{' '}
          </div>
          <div>Estimat Spend : </div>
          <div>
            Total Expenses :{' '}
            {project?.total_expenses
              ?.toFixed(2)
              ?.replace(/\d(?=(\d{3})+\.)/g, '$&,') || 0}{' '}
          </div>
          <div>
            Percentage Of Expenses :{' '}
            {project?.expenses_percentage
              ?.toFixed(2)
              ?.replace(/\d(?=(\d{3})+\.)/g, '$&,') || 0}{' '}
            %
          </div>
          <div className="font-bold py-1 flex gap-2 ">
            Profit :{' '}
            <div
              className={`${
                project?.realprofit > 0 ? 'text-green-700' : 'text-red-700'
              }`}
            >
              {' '}
              {project?.realprofit
                ?.toFixed(2)
                ?.replace(/\d(?=(\d{3})+\.)/g, '$&,') || 0}{' '}
            </div>
          </div>
          <div>
            Percentage Of Profit : {project?.real_percentage?.toFixed(2)} %
          </div>
        </div>
      </Card>
    </div>
  );

  const renderButton = () => (
    <div className="flex justify-end gap-2 py-2">
      <div>
        <Button variant="contained" onClick={handleOpenDeliver} size="small">
          Deliver
        </Button>
      </div>
      {/* <div>
        <Button variant="contained" onClick={handleOpenBilling} size="small">
          Billing
        </Button>
      </div>
      <div>
        <Button variant="contained" onClick={handleOpenPayment} size="small">
          Payment
        </Button>
      </div> */}
      {/* <div>
        <Button variant="contained" color="success">
          Close Job
        </Button>
      </div> */}
    </div>
  );

  const renderTable = () => (
    <ExpensesTableProject
      expenses={project?.expenses}
      page={page}
      size={size}
      setPage={setPage}
      setSize={setSize}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      filter={project?.expenses}
      handleApprove={handleApprove}
      handleSuccess={handleSuccess}
    />
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  if (
    !project?.isLoading &&
    project?.isCompleted &&
    !budget?.isLoading &&
    budget?.isCompleted
  ) {
    return (
      <div>
        {renderModalEdit()}
        {renderModalDeliver()}
        {renderModalPayment()}
        {renderModalBilling()}
        {renderModal()}
        {renderTitle()}

        <div>{renderDailCard()}</div>
        <div>{renderAnalyze()}</div>
        <div>{renderButton()}</div>
        <div>{renderBudget()}</div>
      </div>
    );
  }
  if (project?.isLoading && !project?.isCompleted) {
    return <Loading isLoading />;
  }
  return <Loading isLoading />;
}
