import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { HomeNavbar } from '../components/Nevbars/index';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import { NotFound } from '../components/Error';
// import { Loading } from '../components/Loading';
// import accessRight from '../utils/functions/accessRight';
import { CreateDropletProject } from './Monitor';
import * as actions from '../redux/actions';
import Loading from 'components/Loading';

export default function Home() {
  const dispatch = useDispatch();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const me = useSelector((state) => state.me);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };
  useEffect(() => {
    dispatch(actions.meGet());

    return () => {};
  }, []);
  if (!me.userData) {
    return <Loading />;
  }
  return (
    <div
      className="min-h-screen"
      // style={{
      //   backgroundImage: `url(${process.env.PUBLIC_URL}/your-image-name.jpg)`, // replace with your image URL
      //   backgroundSize: 'cover',
      //   backgroundPosition: 'center',
      // }}
    >
      <MainSidebar
        onMobileClose={handleOnMobileNavClose}
        openMobile={isMobileNavOpen}
        me={me}
      />
      <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
      <div className="lg:ml-64 text-xl lg:text-6xl min-h-screen text-gray-400 y-200 pt-16 px-8 flex justify-center items-center h-screen">
        <div className="py-4">SOTUS CORPORATION</div>
      </div>
      <div className="lg:ml-64">
        <MainFooter />
      </div>
    </div>
  );
}
